import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { restApiHost } from '../utils/appVariables';
import { message } from 'antd';
import TaskTable from '../components/tasks/TaskTable';
import TaskToolbar from '../components/tasks/TaskToobar';
import { convertObjFilterToUrl } from '../utils/getFilters';
import { useLocation } from 'react-router-dom';
import { userInformations } from '../contex';
import { call } from '../apiUtils/call';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { useTypedSelector } from '../store/hooks/useTypedSelector';
import formationDocumentTitle from '../utils/formationDocumentTitle';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';
import useAsyncEffect from 'use-async-effect';

const Task = () => {
  const params = useLocation();
  const [applications, setApplications] = useState([]);
  const [choices, setChoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState(null);
  const [page, setPage] = useState(1);
  const [modalTask, setModalTask] = useState(null);
  const { userInfo } = useContext(userInformations);
  const [visibleFilters, setVisibleFilters] = useState(true);
  const [visibleTableSetting, setVisibleTableSetting] = useState(false);
  const [taskIdForRedirect, setTaskIdForRedirect] = useState(null);
  const notificationCount = useTypedSelector(
    (state) => state.notificationCount.count,
  );
  const scrollingLoadedListRef = useRef(null);
  const isMobile = useCheckMobileScreen();

  useEffect(async () => {
    getApplications();
    getChoicesTask();
  }, []);

  useEffect(() => {
    if (params?.state?.id) {
      setModalTask(params.state);
    } else {
      setModalTask(null);
    }
  }, [params?.state?.id]);

  useAsyncEffect(async () => {
    if (!visibleFilters && !isMobile) {
      await updateTable(1, null, false);
    }
  }, [visibleFilters, isMobile]);

  useEffect(() => {
    if (visibleFilters) {
      setVisibleFilters(false);
    }
  }, [params]);

  const getChoicesTask = async () => {
    try {
      const url = `${restApiHost}/shipping/tasks/`;
      const res = await fetch(url, {
        method: 'OPTIONS',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
      if (res.ok) {
        const json = await res.json();
        json?.actions?.POST?.list_managers?.child?.children?.group?.choices &&
          setChoices(
            json?.actions?.POST?.list_managers?.child?.children?.group?.choices,
          );
      }
    } catch (e) {
      console.log('get customers for task err', e);
    }
  };

  const searchPreApp = async (keyword) => {
    try {
      const url = `${restApiHost}/shipping/task/?search=${keyword}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.tasks) {
        setApplications(json.tasks);
      }
    } catch (e) {
      console.log('search error', e);
      message.error('Ошибка поиска предв. заявки');
    }
  };

  const updateTable = async (page = 1, params, concatData = false) => {
    setPage(page ? page : 1);
    setFilters(params);
    await getApplications(page, params, concatData);
  };

  const getApplications = async (page = 1, params, concatData = false) => {
    setLoading(true);
    let urlParams;

    if (params) {
      urlParams = convertObjFilterToUrl(params, null);
    }
    try {
      const url = urlParams
        ? `${restApiHost}/shipping/tasks/?page=${page}${urlParams}`
        : `${restApiHost}/shipping/tasks/?page=${page}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
        true,
      );

      if (!json) return;

      concatData
        ? setApplications((prevState) => prevState.concat(json?.data))
        : setApplications(json?.data ? json.data : []);
      setTotalRecords(json.count);
      setLoading(false);
    } catch (e) {
      console.log('fetch applications error', e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleScrollToTopMobileList = useCallback(() => {
    if (scrollingLoadedListRef.current?.scrollTop) {
      scrollingLoadedListRef.current.scrollTop = 0;
    }
  }, [scrollingLoadedListRef]);

  return (
    <>
      <Helmet>
        <title>
          {formationDocumentTitle('Заявки | Квик Транс', notificationCount)}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <ErrorBoundary>
            <TaskToolbar
              choices={choices}
              tableFilters={filters}
              pageProps={page}
              applications={applications}
              loading={loading}
              handleUpdate={async (page, params) => {
                isMobile && handleScrollToTopMobileList();
                await updateTable(isMobile ? 1 : page, params, false, false);
              }}
              search={searchPreApp}
              totalTasks={totalRecords}
              setTaskIdForRedirect={(taskId) => {
                setTaskIdForRedirect(taskId);
              }}
              setVisibleFilters={async () => {
                setVisibleFilters(!visibleFilters);
                handleScrollToTopMobileList();
                /*if (visibleFilters) {
                  await updateTable(1, null, false);
                }*/
              }}
              changeVisibleTableSetting={() =>
                setVisibleTableSetting(!visibleTableSetting)
              }
            />
          </ErrorBoundary>
          <Box sx={{ pt: 5 }}>
            <TaskTable
              modalTask={modalTask}
              choices={choices}
              tableFilters={filters}
              visibleFilters={visibleFilters}
              taskIdForRedirect={taskIdForRedirect}
              clearTaskIdForRedirect={() => {
                setTaskIdForRedirect(null);
              }}
              page={page}
              setLoading={(val) => setLoading(val)}
              visibleTableSetting={visibleTableSetting}
              onCloseDrawerTableSetting={() => setVisibleTableSetting(false)}
              totalRecords={totalRecords}
              loading={loading}
              applications={applications}
              updateTable={async (page, params, concatData) => {
                await updateTable(page, params, concatData);
              }}
              scrollingLoadedListRef={scrollingLoadedListRef}
              setVisibleFilters={(visible) => {
                setVisibleFilters(visible);
              }}
              handleScrollToTopMobileList={() => handleScrollToTopMobileList()}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Task;
