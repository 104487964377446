import React, { useState, useContext } from 'react';
import { Button, Form, Modal, Row, Col, Select, message } from 'antd';
import { restApiHost } from '../../utils/appVariables';
import 'moment/locale/ru';
import { call } from '../../apiUtils/call';
import { userInformations } from '../../contex';
import MultipleSelectUseSearch from '../UiComponents/selects/MultipleSelectUseSearch';
import { ErrorBoundary } from '../ErrorBoundary';

const { Option } = Select;

const ResendEmailModal = ({
  open,
  handleClose,
  selectedSendObj,
  activeFolder,
}) => {
  const [form] = Form.useForm();
  const [contacts /*setContacts*/] = useState([]);
  const [loadingSend, setLoadingSend] = useState(false);
  const { userInfo } = useContext(userInformations);

  /*  const handleSearchContacts = useCallback(
    async (keyword = null) => {
      const res = await getEmailContact(userInfo, keyword);
      setContacts(res ?? []);
    },
    [setContacts],
  );*/

  const sendEmail = async (values) => {
    setLoadingSend(true);
    try {
      const formData = new FormData();
      for (let key in values) {
        if (
          (key === 'emails' && values[key]) ||
          (key === 'cc' && values[key]) ||
          (key === 'bcc' && values[key])
        ) {
          Array.isArray(values[key]) &&
            values[key]?.map((item) => formData.append(key, `${item}`));
        } else {
          values[key] && formData.append(key, `${values[key]}`);
        }
      }

      activeFolder && formData.append('folder', `${activeFolder}`);
      selectedSendObj?.uid && formData.append('uid', `${selectedSendObj?.uid}`);

      const url = `${restApiHost}/mail/message/forward/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
          body: formData,
        },
        userInfo.current?.haveViewBranch,
      );

      if (!json) return;

      message.success('Письмо успешно отправлено!');
      handleClose();
    } catch (e) {
      console.log(e);
      message.error('Ошибка отправки письма');
    } finally {
      setLoadingSend(false);
    }
  };

  return (
    <Modal
      visible={open}
      title={'Переслать письмо'}
      width={1000}
      onCancel={() => {
        form.resetFields();
        handleClose();
      }}
      footer={[
        <Button
          key="close"
          onClick={() => {
            form.resetFields();
            handleClose();
          }}
        >
          Закрыть
        </Button>,
        <Button
          key="back"
          type="primary"
          disabled={loadingSend}
          onClick={() => {
            form.validateFields().then((values) => {
              values && sendEmail(values);
            });
          }}
          loading={loadingSend}
        >
          Отправить
        </Button>,
      ]}
    >
      <Form form={form} className={'reset-margin-form'}>
        <Row gutter={15} className={'color-disabled'}>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              rules={[{ required: true, message: 'Обязательное поле!' }]}
              name={'emails'}
            >
              <MultipleSelectUseSearch
                onSearch={() => {}}
                placeholder="Кому"
                style={{
                  width: '100%',
                }}
                options={contacts.map((contact) => {
                  return (
                    <Option key={contact.email} value={contact.email}>
                      {contact.name} {`<${contact.email}>`}
                    </Option>
                  );
                })}
              />
            </Form.Item>
          </Col>

          <Col lg={24} md={24} xs={24}>
            <Form.Item name={'cc'}>
              <ErrorBoundary>
                <MultipleSelectUseSearch
                  onSearch={() => {}}
                  placeholder="Копия"
                  style={{
                    width: '100%',
                  }}
                  options={contacts.map((contact) => {
                    return (
                      <Option
                        key={contact.email}
                        value={`${contact.name} <${contact.email}>`}
                      >
                        {contact.name} {`<${contact.email}>`}
                      </Option>
                    );
                  })}
                />
              </ErrorBoundary>
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Form.Item name={'bcc'}>
              <ErrorBoundary>
                <MultipleSelectUseSearch
                  onSearch={() => {}}
                  placeholder="Скрытая копия"
                  style={{
                    width: '100%',
                  }}
                  options={contacts.map((contact) => {
                    return (
                      <Option
                        key={contact.email}
                        value={`${contact.name} <${contact.email}>`}
                      >
                        {contact.name} {`<${contact.email}>`}
                      </Option>
                    );
                  })}
                />
              </ErrorBoundary>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ResendEmailModal;
