import { Form, Modal, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { userInformations } from '../../../contex';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
const { Option } = Select;

const PermissionModalAddSections = (props) => {
  const [form] = Form.useForm();
  const { userInfo } = useContext(userInformations);
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    if (!props.visible) {
      clearUpVariables();
    }
  }, [props.visible]);

  const handleCancel = () => {
    clearUpVariables();
    props.handleCancel();
  };

  const clearUpVariables = () => {
    form.resetFields();
  };

  const getSections = () => {
    if (userInfo.current?.sections?.length <= 0) return [];
    let addedSectionPermissionsIds = [];
    addedSectionPermissionsIds = props.addedSectionPermissions?.map(
      (section) => {
        return section?.id;
      },
    );
    return userInfo.current?.sections?.filter(
      (section) => !addedSectionPermissionsIds.includes(section?.section_id),
    );
  };

  const handleAddSection = () => {
    if (!props.currentPermissionId) return;
    form.validateFields().then((values) => {
      const body = {
        ...values,
        permission: props.currentPermissionId,
      };
      props.addSectionForPermission(body);
    });
  };

  return (
    <>
      <Modal
        title={'Добавление секции'}
        onCancel={() => handleCancel()}
        onOk={handleAddSection}
        visible={props.visible}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            name="section"
            label="Секция"
            rules={[
              {
                required: true,
                message: 'Выберете секцию!',
              },
            ]}
          >
            <Select
              placeholder={'Секция'}
              getPopupContainer={(trigger) =>
                isMobile ? trigger.parentNode : document.body
              }
            >
              {getSections()?.length > 0 ? (
                <>
                  {getSections().map((item) => {
                    return (
                      <Option key={item?.section_id} value={item?.section_id}>
                        {item?.section || item?.href}
                      </Option>
                    );
                  })}
                </>
              ) : (
                <Option disabled>Нет доступных вариантов</Option>
              )}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

PermissionModalAddSections.propTypes = {
  visible: PropTypes.bool.isRequired,
  addedSectionPermissions: PropTypes.arrayOf(PropTypes.object),
  currentPermissionId: PropTypes.number,
  handleCancel: PropTypes.func.isRequired,
  addSectionForPermission: PropTypes.func.isRequired,
};

export default PermissionModalAddSections;
