import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { Col, message, Row } from 'antd';
import SendMessageModal from '../components/email/SendMessageModal';

import EmailList from '../components/email/new/EmailList';
import EmailBody from '../components/email/new/EmailBody';
import { getNoun } from '../utils/getNoun';
import { userInformations } from '../contex';
import { ErrorBoundary } from '../components/ErrorBoundary';
import formationDocumentTitle from '../utils/formationDocumentTitle';
import { useTypedSelector } from '../store/hooks/useTypedSelector';
import useAsyncEffect from 'use-async-effect';
import EmailSidebar from '../components/email/EmailSidebar';
import {
  changeEmailMessageFlag,
  deleteAllEmailMessages,
  deleteEmailMessage,
  getEmailFolders,
  getMessagesFromFolder,
} from '../logic/email/EmailApiFunctions';
import useStateRef from 'react-usestateref';

//TODO: разобратся с удалением и переноса в spam
const Email = () => {
  const [folders, setFolders] = useState(null);
  const [activeFolder, setActiveFolder] = useState('inbox');

  //send email modal
  const [openSendEmailModal, setOpenSendEmailModal] = useState(false);
  const [loadingSend] = useState(false);

  //emails
  const [, setEmails, emailsRef] = useStateRef([]);
  const [currentPageEmails, setCurrentPageEmails, currentPageEmailsRef] =
    useStateRef(1);
  const [totalEmails, setTotalEmails] = useState(0);
  const [loadingEmails, setLoadingEmails] = useState(false);
  const [activeAll, setActiveAll] = useState(false);
  const [, setSelectedEmails, selectedEmailsRef] = useStateRef([]);
  const [, setDisabledCanDoActions, disabledCanDoActionsRef] =
    useStateRef(false);
  //body email
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [loadingEmail, setLoadingEmail] = useState(false);

  const [flagged, setFlagged] = useState(false);
  const [, setSelectedStaff, selectedStaffRef] = useStateRef(null);
  const { userInfo } = useContext(userInformations);
  const notificationCount = useTypedSelector(
    (state) => state.notificationCount.count,
  );

  useAsyncEffect(async () => {
    await Promise.all([
      handleGetMessagesFromFolderWithReversePagination('inbox'),
      handleGetFolders(),
    ]);
  }, []);

  useEffect(() => {
    setDisabledCanDoActions(selectedStaffRef.current !== null);
  }, [selectedStaffRef.current]);

  const listEmailsForResponse = useMemo(() => {
    if (!selectedEmailsRef.current?.length) return [];

    const filteredArr = selectedEmailsRef.current.map(
      (item) => item?.from_email,
    );
    return [...new Set(filteredArr)];
  }, [selectedEmailsRef]);

  useEffect(() => {
    setSelectedEmails(activeAll ? emailsRef.current : []);
  }, [activeAll]);

  const handleGetFolders = useCallback(
    async (staff_user = null) => {
      const res = await getEmailFolders(userInfo, staff_user);
      const prepareFormatFolders = prepareFoldersFormat(res);
      console.log(prepareFormatFolders);
      setFolders(prepareFormatFolders ?? []);
    },
    [setFolders],
  );

  const prepareFoldersFormat = (folders = []) => {
    function formatFolderName(folderName) {
      return (
        folderName.charAt(0).toUpperCase() + folderName.slice(1).toLowerCase()
      );
    }
    if (!folders?.length) return;
    const objFormat = {};
    for (const folder of folders) {
      if (folder?.name) {
        const formattedName = formatFolderName(folder?.name);
        objFormat[`${formattedName}`] = folder?.count ?? 0;
      }
    }
    return objFormat;
  };

  function calculateTotalPages(totalItems, pageSize) {
    const pageCount = Math.ceil(totalItems / pageSize);
    return pageCount > 0 ? pageCount : 1;
  }
  const handleGetMessagesFromFolderWithReversePagination = useCallback(
    async (folderKey, more = false, flagged = false, staff) => {
      try {
        setLoadingEmails(true);
        let totalPages = 1;
        if (!more) {
          const res = await getMessagesFromFolder(
            folderKey,
            1,
            userInfo,
            staff,
            flagged,
          );
          // Рассчет пагинации
          totalPages = calculateTotalPages(res.count, 10);
          setTotalEmails(res.count ?? 0);
        }

        let page = more
          ? currentPageEmailsRef.current - 1 > 0
            ? currentPageEmailsRef.current - 1
            : 1
          : totalPages;

        setCurrentPageEmails(page);

        console.log(staff, 'staff');
        const { data } = await getMessagesFromFolder(
          folderKey,
          page,
          userInfo,
          staff,
          flagged,
        );
        if (more) {
          setEmails((prevState) => prevState.concat(data.reverse()));
        } else {
          setEmails(data.reverse() ?? []);
        }
        if (page !== 1 && data?.length < 10) {
          await handleGetMessagesFromFolderWithReversePagination(
            folderKey,
            true,
            false,
            staff,
          );
        }
      } finally {
        setLoadingEmails(false);
      }
    },
    [setEmails, currentPageEmails, setCurrentPageEmails, setLoadingEmails],
  );
  /* const handleGetMessagesFromFolder = useCallback(
    async (folderKey, more = false, flagged = false, staff) => {
      try {
        setLoadingEmails(true);
        let page = more ? currentPageEmails + 1 : 1;

        setCurrentPageEmails(page);

        const { data, count } = await getMessagesFromFolder(
          folderKey,
          page,
          userInfo,
          staff,
          flagged,
        );
        if (more) {
          setEmails((prevState) => prevState.concat(data));
        } else {
          setEmails(data ?? []);
        }

        setTotalEmails(count ?? 0);
      } finally {
        setLoadingEmails(false);
      }
    },
    [setEmails, currentPageEmails, setCurrentPageEmails, setLoadingEmails],
  );*/

  const deleteAllEmails = useCallback(async (folder) => {
    const res = await deleteAllEmailMessages(folder, userInfo);
    if (!res) {
      throw new Error('Ошибка удаления');
    }
  }, []);

  const deleteAllEmailsFromDeletedFolder = () => {
    setLoadingEmails(true);
    deleteAllEmails(activeFolder)
      .then(async () => {
        message.success(`Все письма с папки успешно удалены!`);
        setSelectedEmails([]);
        setActiveAll(false);
        await Promise.all([
          handleGetMessagesFromFolderWithReversePagination(
            activeFolder,
            false,
            flagged,
            selectedStaffRef.current,
          ),
          handleGetFolders(selectedStaffRef.current),
        ]);
      })
      .catch((e) => message.error(`Ошибка удаления: ${e}`))
      .finally(() => setLoadingEmails(false));
  };

  const getMessageByUid = useCallback(
    async (emailMessage) => {
      try {
        if (!emailMessage?.uid) return;
        setLoadingEmail(true);
        setSelectedEmail(emailMessage);
        setLoadingEmail(false);
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingEmail(false);
      }
    },
    [setLoadingEmail, setSelectedEmail],
  );

  const sendMessage = async (values) => {
    console.log(values);
  };

  const handleSearchContacts = (keyword) => {
    console.log(keyword);
  };

  const handleShowBodyEmail = async (email) => {
    await getMessageByUid(email, activeFolder);
  };

  const handleDeleteMessages = async () => {
    setLoadingEmails(true);
    Promise.all(
      selectedEmailsRef.current.map(async (email) => {
        await handleDeleteMessage(email?.uid, activeFolder);
      }),
    )
      .then(async () => {
        message.success(
          `Удалено ${selectedEmailsRef.current?.length} ${getNoun(
            selectedEmailsRef.current?.length,
            'письмо',
            'письма',
            'писем',
          )}`,
        );
        setSelectedEmails([]);
        setActiveAll(false);
        await Promise.all([
          handleGetMessagesFromFolderWithReversePagination(
            activeFolder,
            false,
            flagged,
            selectedStaffRef.current,
          ),
          handleGetFolders(selectedStaffRef.current),
        ]);
      })
      .catch(() => message.error(`Ошибка удаления письма`))
      .finally(() => setLoadingEmails(false));
  };

  const handleDeleteMessageInBody = async (email) => {
    setLoadingEmails(true);
    handleDeleteMessage(email?.uid, activeFolder)
      .then(async () => {
        message.success(`Сообщение успешно удалено`);
        setActiveAll(false);
        setSelectedEmail(null);
        setSelectedEmails([]);
        //setCurrentPageEmails(1);
        await Promise.all([
          handleGetMessagesFromFolderWithReversePagination(
            activeFolder,
            false,
            flagged,
            selectedStaffRef.current,
          ),
          handleGetFolders(selectedStaffRef.current),
        ]);
      })
      .catch(() => message.error(`Ошибка удаления`))
      .finally(() => setLoadingEmails(false));
  };

  const updateEmailMessageFlag = (email, systemFlagName, deleteFlag) => {
    const changedEmailIdx = emailsRef.current.findIndex(
      (item) => item.uid === email.uid,
    );
    if (changedEmailIdx >= 0) {
      const newEmails = [...emailsRef.current];
      const flags = [...newEmails[changedEmailIdx]['flags']];
      if (deleteFlag) {
        newEmails[changedEmailIdx] = {
          ...newEmails[changedEmailIdx],
          flags: flags.filter((item) => item !== systemFlagName),
        };
      } else {
        newEmails[changedEmailIdx] = {
          ...newEmails[changedEmailIdx],
          flags: Array.from(new Set([...flags, systemFlagName])),
        };
      }
      console.log(newEmails[changedEmailIdx]);
      setEmails(newEmails);
    }
  };

  const handleMessageToggleFlag = useCallback(
    async (email, flag, flagNameForInstallation, deleteFlag = false) => {
      updateEmailMessageFlag(email, flagNameForInstallation, deleteFlag);
      const json = await changeEmailMessageFlag(
        email?.uid,
        flag,
        activeFolder,
        userInfo,
      );

      if (!json && flagNameForInstallation) {
        updateEmailMessageFlag(email, flagNameForInstallation, deleteFlag);
      }

      if (!json) {
        throw new Error('Ошибка изменения');
      }
    },
    [activeFolder, emailsRef.current],
  );

  const handleDeleteMessage = useCallback(async (emailUid, activeFolder) => {
    const res = await deleteEmailMessage(emailUid, activeFolder, userInfo);

    if (!res) {
      throw new Error('Ошибка удаления');
    }
  }, []);

  const toSpamMessage = async (email) => {
    await handleMessageToggleFlag(email, 'spam', '\\Spam', false);
  };

  const handleFlaggedMessages = useCallback(() => {
    setLoadingEmails(true);
    Promise.all(
      selectedEmailsRef.current.map(async (email) => {
        await handleMessageToggleFlag(
          email,
          'flagged',
          "'Flagged",
          email?.flags?.includes("'Flagged") ?? false,
        );
      }),
    )
      .then(async () => {
        message.success(
          `Помечено важным ${selectedEmailsRef.current?.length} ${getNoun(
            selectedEmailsRef.current?.length,
            'письмо',
            'письма',
            'писем',
          )}`,
        );
        setSelectedEmails([]);
        /* setActiveAll(false);
        setSelectedEmails([]);
        /!*await handleGetMessagesFromFolder(
          activeFolder,
          false,
          flagged,
          selectedStaff,
        );*!/*/
      })
      .catch((e) => {
        console.log(e);
        message.error(`Ошибка запроса для отметки важным`);
      })
      .finally(() => setLoadingEmails(false));
  }, [
    emailsRef.current,
    selectedEmailsRef,
    setLoadingEmails,
    handleMessageToggleFlag,
    setActiveAll,
    setSelectedEmails,
    setEmails,
  ]);

  const handleSpamMessages = async (returnFromSpam = false) => {
    setLoadingEmails(true);
    Promise.all(
      selectedEmailsRef.current.map(async (email) => {
        await toSpamMessage(email);
      }),
    )
      .then(async () => {
        returnFromSpam
          ? message.success(
              `Помечено как спам ${selectedEmailsRef.current?.length} ${getNoun(
                selectedEmailsRef.current?.length,
                'письмо',
                'письма',
                'писем',
              )}`,
            )
          : message.success(
              `Убрано из спама ${selectedEmailsRef.current?.length} ${getNoun(
                selectedEmailsRef.current?.length,
                'письмо',
                'письма',
                'писем',
              )}`,
            );
        setEmails((prevState) =>
          prevState.filter(
            (item) =>
              !selectedEmailsRef.current.some(
                (email) => item.uid === email.uid,
              ),
          ),
        );
        setSelectedEmails([]);
        setActiveAll(false);
      })
      .catch((e) => {
        console.log(e);
        !returnFromSpam
          ? message.error(`Ошибка запроса для отметки писем как спам`)
          : message.error(`Ошибка запроса для возврата писем из спама`);
      })
      .finally(() => setLoadingEmails(false));
  };

  const handleChangeFolder = useCallback(
    async (key) => {
      if (activeFolder === key) {
        return;
      }
      setEmails([]);
      setActiveFolder(key);
      setFlagged(false);
      //setCurrentPageEmails(1);
      setActiveAll(false);
      setSelectedEmail(null);
      await handleGetMessagesFromFolderWithReversePagination(
        key,
        false,
        false,
        selectedStaffRef.current,
      );
    },
    [
      activeFolder,
      setSelectedEmail,
      setActiveFolder,
      setFlagged,
      setCurrentPageEmails,
      setActiveAll,
      setSelectedEmail,
    ],
  );

  return (
    <>
      <Helmet>
        <title>
          {formationDocumentTitle('Почта | Квик Транс', notificationCount)}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '700px',
          py: 3,
        }}
      >
        <Container
          maxWidth={false}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div className="email-container">
            <Row gutter={16}>
              <Col lg={5} md={6} xs={24}>
                <ErrorBoundary>
                  <EmailSidebar
                    flagged={flagged}
                    foldersList={folders}
                    activeFolder={activeFolder}
                    selectedStaff={selectedStaffRef.current}
                    disabledCanDoActions={disabledCanDoActionsRef.current}
                    loading={loadingEmails}
                    setOpenSendEmailModal={() => {
                      setOpenSendEmailModal(true);
                    }}
                    setSelectedStaff={(value) => {
                      setSelectedStaff(value);
                    }}
                    handleChangeFolder={async (key) => {
                      await handleChangeFolder(key);
                    }}
                    getMessagesFromFolder={async () =>
                      await Promise.all([
                        handleGetMessagesFromFolderWithReversePagination(
                          activeFolder,
                          false,
                          flagged,
                          selectedStaffRef.current,
                        ),
                        handleGetFolders(selectedStaffRef.current),
                      ])
                    }
                  />
                </ErrorBoundary>
              </Col>
              <Col lg={19} md={18} xs={24}>
                {!selectedEmail ? (
                  <ErrorBoundary>
                    <EmailList
                      selectedEmails={selectedEmailsRef.current}
                      setSelectedEmails={setSelectedEmails}
                      setActiveAll={setActiveAll}
                      activeAll={activeAll}
                      showBodyEmail={handleShowBodyEmail}
                      emails={emailsRef.current}
                      total={totalEmails}
                      disabledCanDoActions={disabledCanDoActionsRef.current}
                      handleReturnFromSpam={async () => {
                        await handleSpamMessages(true);
                      }}
                      getMessagesFromFolder={async (
                        value = true,
                        value2 = false,
                      ) =>
                        await handleGetMessagesFromFolderWithReversePagination(
                          activeFolder,
                          value,
                          value2,
                          selectedStaffRef.current,
                        )
                      }
                      loading={loadingEmails}
                      handleDeleteMessages={handleDeleteMessages}
                      deleteAllEmailsFromDeletedFolder={
                        deleteAllEmailsFromDeletedFolder
                      }
                      activeFolder={activeFolder}
                      handleShowSendEmailModal={() =>
                        setOpenSendEmailModal(true)
                      }
                      handleFlaggedMessages={handleFlaggedMessages}
                      setImportantFlag={async (email, deleteFlag) => {
                        await handleMessageToggleFlag(
                          email,
                          'flagged',
                          "'Flagged",
                          deleteFlag,
                        );
                      }}
                      setFlagged={setFlagged}
                      flagged={flagged}
                      handleSpamMessages={handleSpamMessages}
                    />
                  </ErrorBoundary>
                ) : (
                  <ErrorBoundary>
                    <EmailBody
                      back={() => {
                        setActiveAll(false);
                        setSelectedEmail(null);
                        setSelectedEmails([]);
                        //setCurrentPageEmails(1);
                      }}
                      disabledCanDoActions={disabledCanDoActionsRef.current}
                      profileInfo={userInfo.current}
                      changeMessageSeen={async (email) => {
                        await handleMessageToggleFlag(email, 'seen', '\\Seen');
                      }}
                      handleShowBodyEmail={handleShowBodyEmail}
                      loading={loadingEmail}
                      activeFolder={activeFolder}
                      getMessagesFromFolder={async () => {
                        await Promise.all([
                          handleGetMessagesFromFolderWithReversePagination(
                            activeFolder,
                            false,
                            flagged,
                            selectedStaffRef.current,
                          ),
                          handleGetFolders(),
                        ]);
                      }}
                      handleDeleteMessageInBody={handleDeleteMessageInBody}
                      email={selectedEmail}
                    />
                  </ErrorBoundary>
                )}
              </Col>
            </Row>
          </div>
        </Container>

        {/*send email modal*/}
        <ErrorBoundary>
          <SendMessageModal
            open={openSendEmailModal}
            loading={loadingSend}
            handleClose={() => {
              setOpenSendEmailModal(false);
              // setSelectedSendObj(null)
            }}
            getMessagesFolders={async () => {
              await handleGetFolders();
            }}
            setDraftFlag={async (email) => {
              await handleMessageToggleFlag(email, 'draft', '\\Draft', false);
            }}
            profileInfo={userInfo.current}
            listEmails={listEmailsForResponse}
            onSearchList={handleSearchContacts}
            onSubmit={sendMessage}
          />
        </ErrorBoundary>
      </Box>
    </>
  );
};

export default Email;
