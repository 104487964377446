import { Avatar, Button, Collapse, message, Skeleton, Tag } from 'antd';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import moment from 'moment';
import EmailBodyToolbar from './EmailBodyToolbar';
import { FileOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ErrorBoundary } from '../../ErrorBoundary';
import ReactShadowRoot from 'react-shadow-root';
import {
  getEmailMessageByUid,
  getMessageAttach,
} from '../../../logic/email/EmailApiFunctions';
import { userInformations } from '../../../contex';
import {
  base64ToFile,
  cleanBase64,
  getBase64WithMime,
} from '../../../utils/base64ToFile';
import formatBodyMessage from '../../../utils/formatBodyMessage';
import useAsyncEffect from 'use-async-effect';

const { Panel } = Collapse;

const DownloadFileBtn = ({ fileName, activeFolder, email, fileSizeBytes }) => {
  const [loading, setLoading] = useState(false);
  const { userInfo } = useContext(userInformations);

  const fileSizeMb = useMemo(() => {
    if (!fileSizeBytes) return 0;
    return fileSizeBytes / (1024 * 1024);
  }, [fileSizeBytes]);

  const handleDownload = async (fileName) => {
    setLoading(true);
    try {
      const res = await getMessageAttach(
        email.uid,
        fileName,
        activeFolder,
        userInfo,
      );

      if (res) {
        const cleanBase64String = cleanBase64(res);
        const base64WithMime = getBase64WithMime(cleanBase64String, fileName);
        console.log(base64WithMime);
        base64ToFile(base64WithMime, fileName);
        message.success(`Файл ${fileName || '-'} успешно скачан`);
      } else {
        message.error('Ошибка запроса на скачивание вложения');
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса на скачивание вложения');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      style={{ marginRight: 10, marginBottom: 10 }}
      icon={<FileOutlined />}
      loading={loading}
      onClick={() => handleDownload(fileName)}
    >
      {fileName || '-'}
      {fileSizeMb ? `(${fileSizeMb} MB)` : ''}
    </Button>
  );
};

const EmailBody = ({
  disabledCanDoActions,
  taskType,
  activeFolder,
  profileInfo,
  getMessagesFromFolder,
  handleShowBodyEmail,
  changeMessageSeen,
  email,
  back,
  handleDeleteMessageInBody,
}) => {
  /*const toEmails = useMemo(() => {
    if (!email?.to_emails?.length) return [];

    const filterToemailFromArray = email.to_emails
      .filter((item) => item.email !== email.to_email)
      .map((item) => `${item.email}<${item.contact_person}>`);
    return [...new Set(filterToemailFromArray)];
  }, [email]);*/
  const { userInfo } = useContext(userInformations);
  const [loading, setLoading] = useState(true);
  const [fullEmailMessage, setFullEmailMessage] = useState(null);

  useAsyncEffect(async () => {
    if (email?.uid) {
      await handleGetFullMessage(email.uid);
    }
  }, [email]);

  const handleGetFullMessage = useCallback(
    async (emailUid) => {
      if (!emailUid) return;
      try {
        const json = await getEmailMessageByUid(
          emailUid,
          activeFolder,
          userInfo,
        );
        if (!json) {
          back();
          return;
        }

        const body = {
          ...json,
          body: formatBodyMessage(json.body),
        };
        setFullEmailMessage(body);
        if (!body?.flags.includes('\\Seen')) {
          changeMessageSeen();
        }
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setFullEmailMessage],
  );

  const emailRecipientCopies = useMemo(() => {
    if (!fullEmailMessage?.cc?.length) return [];
    return [...new Set(fullEmailMessage.cc)];
  }, [fullEmailMessage]);

  return loading ? (
    <div style={{ padding: '10px', minHeight: '700px' }}>
      <Skeleton loading={true} active={true} />
      <Skeleton loading={true} active={true} />
      <Skeleton loading={true} active={true} />
      <Skeleton loading={true} active={true} />
    </div>
  ) : (
    <div style={{ minHeight: '700px' }}>
      <ErrorBoundary>
        <EmailBodyToolbar
          activeFolder={activeFolder}
          taskType={taskType}
          back={back}
          email={fullEmailMessage}
          profileInfo={profileInfo}
          disabledCanDoActions={disabledCanDoActions}
          getMessagesFromFolder={getMessagesFromFolder}
          handleShowBodyEmail={handleShowBodyEmail}
          changeMessageSeen={changeMessageSeen}
          handleDeleteMessageInBody={handleDeleteMessageInBody}
        />
      </ErrorBoundary>
      <div className={'email-body'}>
        <div style={{ borderBottom: '1px solid #ddd' }}>
          <div style={{ display: 'flex' }}>
            <Avatar
              style={{
                fontSize: 30,
                height: 70,
                width: 70,
                lineHeight: '70px',
                marginRight: 5,
              }}
            >
              {fullEmailMessage?.from_email &&
                fullEmailMessage?.from_email.slice(0, 1).toUpperCase()}
            </Avatar>
            <div>
              {fullEmailMessage?.date ? (
                <div className="email-message__item-date">
                  {moment(fullEmailMessage.date).format('dddd')}{' '}
                  {moment(fullEmailMessage.date).format('L')}{' '}
                  {moment(fullEmailMessage.date).format('LT')}
                </div>
              ) : (
                <div className="email-message__item-date">
                  {moment().format('dddd')} {moment().format('L')}{' '}
                  {moment().format('LT')}
                </div>
              )}
              <div
                style={{ display: 'flex', alignItems: 'center', fontSize: 20 }}
              >
                <div className="email-message__item-date">
                  {fullEmailMessage?.from_email}
                </div>
              </div>
              <b>
                {!fullEmailMessage?.subject
                  ? 'Без темы'
                  : fullEmailMessage.subject}
              </b>
            </div>
          </div>
          <div style={{ margin: '20px 0' }}>
            {fullEmailMessage?.to_emails?.length > 1 ? (
              <Collapse ghost>
                <Panel
                  header={`Кому: ${
                    fullEmailMessage?.to_emails[0]
                      ? fullEmailMessage.to_emails[0]
                      : 'Без email-а'
                  } и еще +${fullEmailMessage?.to_emails?.length - 1}`}
                  key="1"
                >
                  {fullEmailMessage?.to_emails.map((item, index) => (
                    <Tag key={index}>{item}</Tag>
                  ))}
                </Panel>
              </Collapse>
            ) : (
              <div style={{ display: 'flex' }}>
                <p>Кому:</p>
                <div style={{ marginLeft: 5 }}>
                  <Avatar size="small" style={{ marginRight: 5 }}>
                    {fullEmailMessage?.to_emails &&
                    fullEmailMessage?.to_emails[0]
                      ? fullEmailMessage?.to_emails[0].slice(0, 1).toUpperCase()
                      : '!'}
                  </Avatar>
                  {fullEmailMessage?.to_emails[0]
                    ? fullEmailMessage.to_emails[0]
                    : 'Без email-а'}
                </div>
              </div>
            )}
            {emailRecipientCopies.length > 0 && (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <p>Копии:</p>
                {emailRecipientCopies.map((email, index) => {
                  return (
                    <div key={index} style={{ marginLeft: 5 }}>
                      <Avatar size="small" style={{ marginRight: 5 }}>
                        {email ? email.slice(0, 1).toUpperCase() : '!'}
                      </Avatar>
                      {email ? email : 'Без email-а'}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {fullEmailMessage?.files?.length > 0 && (
            <div style={{ borderTop: '1px solid #ddd', padding: '10px 0' }}>
              {fullEmailMessage.files.map((item, index) => {
                return (
                  <ErrorBoundary key={index}>
                    <DownloadFileBtn
                      key={item.id}
                      fileName={item?.name}
                      activeFolder={activeFolder}
                      email={fullEmailMessage}
                      disabledCanDoActions={disabledCanDoActions}
                    />
                  </ErrorBoundary>
                );
              })}
            </div>
          )}
          {!taskType && fullEmailMessage?.related_task?.length > 0 && (
            <Collapse ghost className={'collapse-email'} defaultActiveKey={'1'}>
              <Panel
                header={`Привязанных сделок ${fullEmailMessage.related_task.length}`}
                key="1"
              >
                {fullEmailMessage.related_task.map((item) => {
                  return item?.number_task ? (
                    <Link to={'/applications'} state={item}>
                      {item?.number_task}{' '}
                      {item?.customer?.short_name || item?.customer?.legal_name}
                    </Link>
                  ) : (
                    <Link to={'/pre-applications'} state={item}>
                      {item.pre_task_number} {item?.lead?.company_name}
                    </Link>
                  );
                })}
              </Panel>
            </Collapse>
          )}
        </div>
        {fullEmailMessage?.html_body && (
          <div style={{ marginTop: 10, overflow: 'auto' }}>
            <ErrorBoundary>
              <ReactShadowRoot>
                <div
                  dangerouslySetInnerHTML={{
                    __html: fullEmailMessage.html_body,
                  }}
                />
              </ReactShadowRoot>
            </ErrorBoundary>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailBody;
