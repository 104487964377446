import { Button, Select, Skeleton } from 'antd';
import {
  BookOutlined,
  CloseSquareOutlined,
  DeleteFilled,
  EditOutlined,
  InboxOutlined,
  ReloadOutlined,
  SendOutlined,
} from '@ant-design/icons';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { searchStaffList } from '../../logic/staff/StaffApiFunctions';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import { userInformations } from '../../contex';
import useAsyncEffect from 'use-async-effect';
import PropTypes from 'prop-types';

const { Option } = Select;

const EmailSidebar = (props) => {
  const isMobile = useCheckMobileScreen();
  const { userInfo } = useContext(userInformations);
  const [staffList, setStaffList] = useState([]);

  useAsyncEffect(async () => {
    await handleGetManagersList();
  }, []);

  const inboxCount = useMemo(() => {
    return String(props.foldersList?.Inbox ?? 0);
  }, [props.foldersList]);

  const sentCount = useMemo(() => {
    return String(props.foldersList?.Sent ?? 0);
  }, [props.foldersList]);

  const draftsCount = useMemo(() => {
    return String(props.foldersList?.Drafts ?? 0);
  }, [props.foldersList]);

  const deletedCount = useMemo(() => {
    return String(props.foldersList?.Trash ?? 0);
  }, [props.foldersList]);

  const canViewStaffEmails = useMemo(() => {
    if (!userInfo.current?.groups?.length) return false;

    const isDirector = userInfo.current.groups.filter(
      (item) => item.id === 1 || item.id === 2,
    );

    return isDirector?.length;
  }, [userInfo.current, props.selectedStaff]);

  const handleGetManagersList = useCallback(
    async (keyword = null) => {
      try {
        const res = await searchStaffList(keyword, userInfo);
        if (res?.data) {
          const filteredAuthPassMail = res?.data.filter(
            (item) => item?.auth_pass_mail === true,
          );
          setStaffList(filteredAuthPassMail);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [setStaffList, userInfo],
  );

  const handleChangeStaffSelect = useCallback(
    async (value = null) => {
      if (value) {
        props.setSelectedStaff(value);
        await props.getMessagesFromFolder(
          props.activeFolder,
          false,
          props.flagged,
          value,
        );
        return;
      }

      props.setSelectedStaff(null);
      await props.getMessagesFromFolder(
        props.activeFolder,
        false,
        props.flagged,
      );
    },
    [props.selectedStaff, props.setSelectedStaff, props.getMessagesFromFolder],
  );

  const handleChangeFolder = useCallback(
    async (key) => {
      if (props.activeFolder === key) {
        return;
      }
      await props.handleChangeFolder(key);
    },
    [props.activeFolder, props.handleChangeFolder],
  );

  return (
    <>
      <div className="email-menu">
        {canViewStaffEmails && (
          <Select
            getPopupContainer={(trigger) =>
              isMobile ? trigger.parentNode : document.body
            }
            onChange={async (value) => {
              await handleChangeStaffSelect(value);
            }}
            showSearch
            allowClear
            loading={props.loading}
            disabled={props.loading}
            onSearch={async (value) => {
              await handleGetManagersList(value);
            }}
            filterOption={false}
            style={{ width: '100%' }}
            placeholder="Выберите сотрудника"
          >
            {staffList &&
              staffList?.length > 0 &&
              staffList.map((item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item?.full_name || item.email}
                  </Option>
                );
              })}
          </Select>
        )}
        <div className="send-message-container">
          <Button
            onClick={() => props.setOpenSendEmailModal(true)}
            style={{ marginRight: 10, borderRadius: 5, fontSize: 13 }}
            type="primary"
            disabled={props.disabledCanDoActions}
            icon={<EditOutlined />}
          >
            Написать
          </Button>
          <Button
            onClick={async () => {
              //setCurrentPageEmails(1);
              await props.getMessagesFromFolder(
                props.activeFolder,
                false,
                props.flagged,
                props.selectedStaff,
              );
            }}
            disabled={props.loading}
            style={{ borderRadius: 5, fontSize: 13 }}
            type="primary"
            icon={<ReloadOutlined />}
          />
        </div>

        <div className={'email-menu__list'} title={inboxCount}>
          {props.loading ? (
            <Skeleton.Input active={true} style={{ marginTop: '8px' }} />
          ) : (
            <div
              className={`email-menu__list-item${
                props.activeFolder === 'inbox' ? ' active' : ''
              }`}
              onClick={() => handleChangeFolder('inbox')}
            >
              <div>
                <InboxOutlined /> Входящие
              </div>
              {inboxCount && <span>{inboxCount}</span>}
            </div>
          )}

          {props.loading ? (
            <Skeleton.Input active={true} style={{ marginTop: '8px' }} />
          ) : (
            <div
              className={`email-menu__list-item${
                props.activeFolder === 'Sent' ? ' active' : ''
              }`}
              onClick={() => handleChangeFolder('Sent')}
              title={sentCount}
            >
              <div>
                <SendOutlined /> Отправленные
              </div>
              {sentCount && <span>{sentCount}</span>}
            </div>
          )}

          {!props.selectedStaff && (
            <>
              {props.loading ? (
                <Skeleton.Input active={true} style={{ marginTop: '8px' }} />
              ) : (
                <div
                  className={`email-menu__list-item${
                    props.activeFolder === 'drafts' ? ' active' : ''
                  }`}
                  onClick={() => handleChangeFolder('drafts')}
                  title={draftsCount}
                >
                  <div>
                    <BookOutlined /> Черновики
                  </div>
                  {draftsCount && <span>{draftsCount}</span>}
                </div>
              )}

              {props.loading ? (
                <Skeleton.Input active={true} style={{ marginTop: '8px' }} />
              ) : (
                <div
                  className={`email-menu__list-item${
                    props.activeFolder === 'deleted' ? ' active' : ''
                  }`}
                  onClick={() => handleChangeFolder('trash')}
                  title={deletedCount}
                >
                  <div>
                    <DeleteFilled /> Удаленные
                  </div>
                  {deletedCount && <span>{deletedCount}</span>}
                </div>
              )}

              {props.loading ? (
                <Skeleton.Input active={true} style={{ marginTop: '8px' }} />
              ) : (
                <div
                  className={`email-menu__list-item${
                    props.activeFolder === 'Spam' ? ' active' : ''
                  }`}
                  onClick={() => handleChangeFolder('Spam')}
                >
                  <div>
                    <CloseSquareOutlined /> Спам
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EmailSidebar;

EmailSidebar.propTypes = {
  selectedStaff: PropTypes.arrayOf(PropTypes.number).isRequired,
  flagged: PropTypes.bool.isRequired,
  foldersList: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabledCanDoActions: PropTypes.bool,
  loading: PropTypes.bool,
  setSelectedStaff: PropTypes.func.isRequired,
  setOpenSendEmailModal: PropTypes.func.isRequired,
  getMessagesFromFolder: PropTypes.func.isRequired,
  handleChangeFolder: PropTypes.func.isRequired,
};
