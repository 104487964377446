export function base64ToFile(base64String, fileName) {
  if (!base64String) return;

  const [metadata, base64Data] = base64String.split(',');

  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  const fileBlob = new Blob([byteArray], {
    type: metadata.split(':')[1].split(';')[0],
  });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(fileBlob);
  link.download = fileName;

  link.click();
}

export function cleanBase64(base64String) {
  return base64String.replace(/(\r\n|\n|\r)/gm, '').trim();
}

function detectMimeType(base64String, fileName) {
  let ext = fileName.substring(fileName.lastIndexOf('.') + 1);
  if (ext === undefined || ext === null || ext === '') ext = 'bin';
  ext = ext.toLowerCase();
  const signatures = {
    JVBERi0: 'application/pdf',
    R0lGODdh: 'image/gif',
    R0lGODlh: 'image/gif',
    iVBORw0KGgo: 'image/png',
    TU0AK: 'image/tiff',
    '/9j/': 'image/jpg',
    UEs: 'application/vnd.openxmlformats-officedocument.',
    PK: 'application/zip',
  };
  for (let s in signatures) {
    if (base64String.indexOf(s) === 0) {
      let x = signatures[s];
      if (ext.length > 3 && ext.substring(0, 3) === 'ppt') {
        x += 'presentationml.presentation';
      } else if (ext.length > 3 && ext.substring(0, 3) === 'xls') {
        x += 'spreadsheetml.sheet';
      } else if (ext.length > 3 && ext.substring(0, 3) === 'doc') {
        x += 'wordprocessingml.document';
      }
      return x;
    }
  }
  const extensions = {
    xls: 'application/vnd.ms-excel',
    ppt: 'application/vnd.ms-powerpoint',
    doc: 'application/msword',
    xml: 'text/xml',
    mpeg: 'audio/mpeg',
    mpg: 'audio/mpeg',
    txt: 'text/plain',
  };
  for (let e in extensions) {
    if (ext.indexOf(e) === 0) {
      let xx = extensions[e];
      return xx;
    }
  }
  return 'application/octet-stream';
}

export function getBase64WithMime(clearBase64, filename) {
  if (!clearBase64) return;

  const mimeType = detectMimeType(clearBase64, filename);

  return `data:${mimeType};base64,${clearBase64}`;
}
